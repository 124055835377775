const makeHighlightStyle = theme => ({colors: {primary, secondary}, before: {content}}) => ({
  borderLeftColor: primary,
  backgroundColor: secondary,
  '&:before': {
    color: primary,
    content,
    fontFamily: 'Libre Franklin,Material Icons',
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(3),
    fontSize: 24,
    fontWeight: 400,
  },
  ...theme.typography.paper,
  borderLeft: '4px solid',
  padding: theme.spacing(6, 3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6, 8),
  },
  margin: theme.spacing(2, 0, 5),
  borderRadius: theme.spacing(1 / 2),
  backgroundRepeat: 'no-repeat',
  backgroundSize: '32px 24px',
  backgroundPosition: 'top 9px right 9px',
  position: 'relative',
  '& :first-child': {
    marginTop: 0,
  },
  '& :last-child': {
    marginBottom: 0,
  },
});

export default makeHighlightStyle;
