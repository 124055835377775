import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '@material-ui/icons/Menu';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const HoverMenu = ({handleClick}) => {
  const classes = useStyles();

  return (
      <div onClick={handleClick} role="presentation" className={classes.root}>
        <Fab color="primary" size="small" aria-label="open_menu" style={{boxShadow: 1}}>
          <MenuIcon />
        </Fab>
      </div>
  );
};

export default HoverMenu;
