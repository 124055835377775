import React, {useEffect} from 'react';

const src = 'https://utteranc.es/client.js';
const branch = 'master';
const DARK_THEME = 'photon-dark';
const LIGHT_THEME = 'github-light';

const Comments = ({repo, themeMode}) => {
  const rootElm = React.createRef();

  useEffect(() => {
    const utterances = document.createElement('script');
    const utterancesConfig = {
      src,
      repo,
      branch,
      theme: themeMode === 'light' ? LIGHT_THEME : DARK_THEME,
      label: 'comment',
      async: true,
      'issue-term': 'title',
      crossorigin: 'anonymous',
    };

    Object.keys(utterancesConfig).forEach(configKey => {
      utterances.setAttribute(configKey, utterancesConfig[configKey]);
    });
    rootElm.current.appendChild(utterances);
  }, []);

  return <div className="utterances" ref={rootElm} />;
};
export default Comments;
