import {Helmet} from 'react-helmet';
import React from 'react';
import {defaultOgImage, defaultOgDescription} from 'locales/en';

const defaultTitle = 'Ashu Desai | Founder Coach';

const contextToSeo = pageContext => {
  const page = pageContext?.frontmatter;
  return {
    title:
      (page && `${page.title}`) ||
      defaultTitle,
    description: page?.description || defaultOgDescription,
    image: page?.image || defaultOgImage,
    url: `https://ashu.xyz/${(page ?? page)?.slug || ''}`,
  };
};

const SEO = incoming => {
  const {pageContext} = incoming;
  const {title, description, image, url, twitterUsername} = contextToSeo(pageContext);
  const noScriptText = `${defaultOgDescription} Try enabling Javascript to view this full page!`;
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitterUsername} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={image} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <noscript>{noScriptText}</noscript>
      </Helmet>
    </>
  );
};

export default SEO;
