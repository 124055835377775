export const light = {
  alternate: {
    main: 'rgb(247, 249, 250)',
    dark: '#e8eaf6',
    theme: 'rgb(247, 249, 250)',
    contrast: '#e8eaf6',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: '#000000',
    light: 'rgb(71, 145, 219)',
    dark: 'rgb(111, 80, 95)',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ffb74d',
    main: '#f9b934',
    dark: '#f57c00',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#1e1e21',
    secondary: '#49373d',
    contrast: 'rgb(200, 80, 40)',
  },
  divider: '#1e1e21',
  background: {
    paper: '#fff',
    default: '#fff',
    level3: '#c1c1c1',
    level2: '#f5f5f5',
    level1: '#fff',
    footer: '#2D3748',
  },
  hint: {
    red: {
      light: '#F4A79D',
      main: '#F0897D',
      dark: '#EC6C5C',
    },
    green: {
      light: '#6BD4B6',
      main: '#39C69D',
      dark: '#08B885',
    },
    orange: {
      light: '#FCD3A9',
      main: '#FBC48C',
      dark: '#FAB56F',
    },
  },
  legacy: {
    actionHighlight: {
      quote: {
        primary: 'grey',
        secondary: '#f5f5f5',
      },
      info: {
        primary: '#618fff',
        secondary: '#F8FBFF',
      },
      action: {
        primary: '#FFA342',
        secondary: '#FFFAF5',
      },
      solution: {
        primary: '#928908',
        secondary: '#FFFEF4',
      },
      challenge: {
        primary: '#147509',
        secondary: '#efffed',
      },
    },
    syntaxHighlight: {
      reserved: '#A90D91',
      literal: '#1C01CE',
      comment: '#177500',
      special: '#C41A16',
      preproc: '#633820',
      attribute: '#836C28',
      className: '#3F6E75',
      character: '#2300CE',
      psuedo: '#5B269A',
      background: '#f5f5f5',
    },
  },
};

export const dark = {
  alternate: {
    main: '#2D3748',
    dark: '#24242b',
    theme: '#24242b',
    contrast: '#2D3748',
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  common: {
    black: '#000',
    white: '#fff',
  },
  type: 'dark',
  primary: {
    main: 'rgb(100, 141, 174)',
    light: 'rgb(166, 212, 250)',
    dark: 'rgb(111, 80, 95)',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  secondary: {
    light: '#ffb74d',
    main: '#f9b934',
    dark: '#f57c00',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: 'rgb(212, 208, 203)',
    secondary: '#AEB0B4',
    contrast: 'rgb(100, 141, 174)',
  },
  divider: 'rgb(212, 208, 203)',
  background: {
    paper: 'rgb(12, 14, 16)',
    default: '#121212',
    level3: '#2b2b2b',
    level2: '#333333',
    level1: '#2d3748',
    footer: '#18181f',
  },
  hint: {
    red: {
      light: '#F4A79D',
      main: '#F0897D',
      dark: '#EC6C5C',
    },
    green: {
      light: '#6BD4B6',
      main: '#39C69D',
      dark: '#08B885',
    },
    orange: {
      light: '#FCD3A9',
      main: '#FBC48C',
      dark: '#FAB56F',
    },
  },
  legacy: {
    actionHighlight: {
      quote: {
        primary: 'grey',
        secondary: '#292929',
      },
      info: {
        primary: '#618fff',
        secondary: '#002e4f',
      },
      action: {
        primary: '#FFA342',
        secondary: '#432300',
      },
      solution: {
        primary: '#faec48',
        secondary: '#282600',
      },
      challenge: {
        primary: '#5AFF42',
        secondary: '#022800',
      },
    },
    syntaxHighlight: {
      reserved: '#e034c1',
      literal: '#7768d9',
      comment: '#64b350',
      special: '#cf4b46',
      preproc: '#ab5320',
      attribute: '#ecb54b',
      className: '#82b3be',
      character: '#af67e9',
      psuedo: '#9544ff',
      background: '#333333',
    },
  },
};
