import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import CloseIcon from '@material-ui/icons/Close';
import {Box, Dialog, IconButton, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  dialog: {
      backgroundColor: 'transparent',
      elevation: 20,
  },
  dialogBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  dialogImage: {
    maxWidth: '100%',      // Ensures the image is not wider than the screen
    maxHeight: '90vh', 
  },
  dBlock: {
    display: 'block',
  },
  closeButton: {
    position: 'fixed',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.divider,
  },
  wideImage: {
    width: '100%',
    height: '100%',
    marginBottom: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      marginTop: '7%',
      marginBottom: '7%',
      marginLeft: '-15%', // Extend 15% more to the left
      marginRight: '-15%', // Extend 15% more to the right
      width: '130%', // Increase total width to 130%
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '10%',
      marginBottom: '10%',
      marginLeft: '-25%', // Extend 15% more to the left
      marginRight: '-25%', // Extend 15% more to the right
      width: '150%', // Increase total width to 130%
    },
  },
}));

/**
 * Component to display the images
 *
 * @param {Object} props
 */
const Image = props => {
  const {src, wide, srcSet, alt, lazy, lazyProps, className, ...rest} = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleOpen = () => {
    // Store the current scroll position before opening the dialog
    setScrollPosition(window.scrollY);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // Restore the scroll position after the dialog closes
    const maxScroll = document.documentElement.scrollHeight - window.innerHeight - 1;
    window.scrollTo(0, Math.min(scrollPosition, maxScroll));
  };

  const renderImage = () => (
    <img
    className={clsx('image', classes.root, wide && classes.wideImage, className)}
    alt={alt}
      src={src}
      srcSet={srcSet}
      {...rest}
    />
  );

  const renderDialogImage = () => (
    <img
    className={clsx(classes.dialogImage, className )}
    alt={alt}
      src={src}
      srcSet={srcSet}
    />
  );

  return (
    <Box mt={wide ? 5 : 0}>
      <div onClick={handleOpen}>
        {lazy ? (
          <LazyLoadImage
            className={clsx('image', classes.root, wide && classes.wideImage, classes.dBlock, className)}
            alt={alt}
            src={src}
            srcSet={srcSet}
            effect="opacity"
            {...lazyProps}
            {...rest}
          />
        ) : (
          renderImage()
        )}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        // className={classes.dialog}
        maxWidth="lg"
        PaperProps={{
          className: classes.dialog,
          square: true,
        }}
        BackdropProps={{
          className: classes.dialogBackdrop,
        }}
      >
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        {renderDialogImage()}
      </Dialog>
    </Box>
  );
};

Image.defaultProps = {
  alt: '...',
  lazy: true,
  wide: '',
  lazyProps: {
    width: 'auto',
    height: 'auto',
  },
};

Image.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Source of the image
   */
  src: PropTypes.string.isRequired,
  /**
   * Source set for the responsive images
   */
  srcSet: PropTypes.string,
  /**
   * Image title
   */
  alt: PropTypes.string,
  /**
   * Lazy loading properties
   */
  lazyProps: PropTypes.object,
  /**
   * Should lazy load the image
   */
  lazy: PropTypes.bool,
  /**
   * Should lazy load the image
   */
  wide: PropTypes.string,
};

export default Image;
