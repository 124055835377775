import React from 'react';
import {Box, Grid, useMediaQuery, makeStyles} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import {Image} from 'components/atoms';

const useStyles = makeStyles(theme => ({
  wide: {
    [theme.breakpoints.up('md')]: {
      marginTop: '7%',
      marginBottom: '7%',
      marginLeft: '-15%', // Extend 15% more to the left
      marginRight: '-15%', // Extend 15% more to the right
      width: '130%', // Increase total width to 130%
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '10%',
      marginBottom: '10%',
      marginLeft: '-25%', // Extend 15% more to the left
      marginRight: '-25%', // Extend 15% more to the right
      width: '150%', // Increase total width to 130%
    },
    
  },
}));

const ImageTriplet = ({src1, src2, src3, ...rest}) => {
    const theme = useTheme();
    const classes = useStyles();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true,
    });
  return (
    <>
      <Box mt={4} className={classes.wide}>
        <Grid container spacing={isSm ? 1 : 3}>
            <Grid item xs={4}>
                <Image src={src1}/>
            </Grid>
            <Grid item xs={4}>
                <Image src={src2}/>
            </Grid>
            <Grid item xs={4}>
                <Image src={src3}/>
            </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ImageTriplet;
