import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  slidesWrapper: {
    paddingTop: '63.2%' /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '70%'
    },
    marginBottom: theme.spacing(8),
    width: '100%',
    position: 'relative',
  },
  googleWrapper: {
    paddingTop: '60%' /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
    width: '100%',
    position: 'relative',
  },
  embed: {
    position: 'absolute',
    top: 10,
    left: 0,
    bottom: 0,
    right: 0,
    margin: '0 auto',
    width: '100%',
    height: '100%',
    boxShadow: 'rgba(50, 50, 50, 0.18) 0px 0px 24px',
  },
}));

const Slides = ({type, title, contentSrc}) => {
  const embed =
    type === 'google'
      ? `https://docs.google.com/presentation/d/e/${contentSrc}/embed?start=false&loop=false&delayms=3000`
      : `https://www.icloud.com/keynote/${contentSrc}?embed=true`;

  const classes = useStyles();
  const wrapper = type === 'google' ? classes.googleWrapper : classes.slidesWrapper;

  return (
    <Box className={wrapper}>
      <iframe
        title={title}
        className={classes.embed}
        src={embed}
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </Box>
  );
};

Slides.propTypes = {
  type: PropTypes.oneOf(['google', 'html']).isRequired,
  title: PropTypes.string.isRequired,
  contentSrc: PropTypes.string.isRequired,
};

export default Slides;
